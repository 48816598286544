import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { initiateWhatsApp } from "../../actions";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import store from "../../store";
import './css/style.css'; 


const Chat = (props) => {
    const _reCaptchaRef = useRef(null)
    const _form = React.createRef()

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const validator = useRef(new SimpleReactValidator())

    // const [isChatOpen, setIsChatOpen] = useState(false);
    const [Data, setData] = useState({
        name: "",
        countryCode: "",
        mobile: "",
        email: "",
        message: "",
    });

    const clearData = () => {
        setData({
            name: "",
            countryCode: "",
            mobile: "",
            email: "",
            message: "",
        });
    }

    const toggleChat = () => {
        // setIsChatOpen(!isChatOpen);
        store.dispatch({ type: "WHATSAPP_CHAT"});
    };

    const onChangeHandler = async (event) => {
        event.persist()
        setData(prevState => ({
            ...prevState, [event.target.name]: event.target.value
        }))
    }

    const handleCountryCode = (value, country, e, formattedValue) => {
        setData((prevState) => ({
            ...prevState,
            'countryCode': formattedValue,
            'mobile': value
        }))
    };

    const onRecaptcha = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            _reCaptchaRef.current.executeAsync();
            validator.current.hideMessages();
        } else {
            validator.current.showMessages();
            forceUpdate();
        }
    }

    const handleSubmit = () => {
        _form.current.reset();

        props.initiateWhatsApp(Data);
        _reCaptchaRef.current.reset();
        validator.current.hideMessages();
        toggleChat();
        clearData();
        toast.success("WhatsApp Chat is Initiated Successfully", { autoClose: 5000, hideProgressBar: true, })
    };

    return (
        <div id="chatContainer">
            <button id="chatButton" className="uk-button uk-button-primary uk-border uk-border-pill uk-position-fixed uk-flex uk-flex-middle uk-margin-small-bottom uk-margin-small-right uk-padding-small uk-position-bottom-right uk-button-hover d-none d-lg-block" onClick={toggleChat}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg> <span className="ml-2"><b>Chat with us</b></span>
            </button>

            {props.isChatOpen && (
                <div
                    id="chatFormOverlay"
                    className={`uk-card uk-card-default uk-card-small uk-position-fixed uk-position-bottom-right uk-background-white uk-box-shadow-large uk-border-rounded ${props.isChatOpen ? '' : 'uk-hidden'}`}
                    style={{ width: '300px', zIndex: 1111111 }}
                >
                    <div className="uk-card-header uk-flex uk-flex-middle uk-flex-between" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="uk-text-bold">Sunny Diamonds Support</span>
                        <button
                            type="button"
                            id="closeChatForm"
                            data-uk-close
                            className="uk-position-top-right uk-padding-small"
                            onClick={toggleChat}
                        />
                    </div>
                    <form id="chatForm"
                        className="uk-card-body uk-form-stacked"
                        ref={_form}
                        onSubmit={onRecaptcha}
                    >
                        <div className="uk-margin">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="uk-input rounded"
                                placeholder="Your Name"
                                value={Data.name}
                                onChange={onChangeHandler}
                            />
                            {validator.current.message("name", Data.name, "required|alpha_space")}
                        </div>
                        <div className="uk-margin">
                            {/* <input
                                type="tel"
                                id="mobile"
                                name="mobile"
                                className="uk-input rounded"
                                placeholder="Your Mobile"
                                value={Data.mobile}
                                onChange={onChangeHandler}
                            /> */}


                            <PhoneInput
                                className="uk-input rounded uk-width-1-1 w-100"
                                id="form-horizontal-text"
                                country={"in"}
                                value={Data.mobile}
                                name='mobile'
                                onChange={handleCountryCode}
                                isValid={(value, country) => {
                                    if (country.iso2 !== "in") {
                                        return true;
                                    }
                                    const regex = /^[789]\d{11}$/;
                                    if (value.length > 3 && !regex.test(value)) {
                                        return "Invalid Indian phone number";
                                    }
                                    return true;
                                }}
                            />

                            {validator.current.message("mobile", Data.mobile, "required|phone|min:12|max:15")}
                        </div>
                        <div className="uk-margin">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="uk-input rounded"
                                placeholder="Your Email"
                                value={Data.email}
                                onChange={onChangeHandler}
                            />
                            {validator.current.message("email", Data.email, "required|email")}
                        </div>
                        <div className="uk-margin">
                            <textarea
                                id="message"
                                name="message"
                                className="uk-textarea rounded"
                                placeholder="Your Message"
                                value={Data.message}
                                onChange={onChangeHandler}
                                rows="3"
                            />
                        </div>
                        <ReCAPTCHA
                            ref={_reCaptchaRef}
                            size="invisible"
                            sitekey={global.reCaptcha}
                            onChange={handleSubmit}
                        />
                        <button
                            type="submit"
                            className="uk-button uk-button-primary buy-now uk-width-1-1"
                        >
                            <small><b>SUBMIT</b></small>
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    isChatOpen: state.chat.isChatOpen
});
export default connect(mapStateToProps, { initiateWhatsApp })(Chat);
