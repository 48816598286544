import { WHATSAPP_CHAT } from '../constants/ActionTypes'

const initialState = {
    isChatOpen: false
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case WHATSAPP_CHAT:
            return {
                ...state,
                isChatOpen: !state.isChatOpen
            };
        default:
            return state;
    }
}

export default chatReducer;
