import React, { Component } from 'react';

// Custom Components
import Header from './new_header'
import { MainLoader } from '../loader';
import Footer from './new_footer';

import CookieConsent from "../components/new_template_common/cookie_consents/index";

// ThemeSettings
import ThemeSettings from "./common/theme-settings"
import { connect } from 'react-redux';
import TryAtHomeCart from './pages/try-at-home/try_at_home_cart';
import Chat from './whatsapp_chat/Chat';

const App = (props) => {

    function showLoader() {
        if (props.loader) return (<MainLoader />)
        else return null
    }


    return (
        <div>
            {/* {showLoader()} */}
            <Header location={props.location} />
            {props.children}
            <Footer />
            {/* {showTryAtHomeCart()} */}
            <CookieConsent />
            {/* <ToastContainer autoClose={1000} className="toast-container" /> */}
            <ThemeSettings />
            {/* <Chat /> */}
        </div>
    );
}

const mapStateToProps = (state) => ({
    loader: state.data.loader,
})

export default connect(mapStateToProps)(App);