import { combineReducers } from 'redux';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'

// Import custom components
import productReducer from './products';
import themeReducer from './theme';
import cartReducer from './cart';
import filtersReducer from './filters';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import timerReducer from './timer';
import giftCardReducer from './gift-card';
import otpReducer from './otp';
import CareerReducer from './career-data';
import BannerReducer from './banner';
import EnquiryReducer from './enquiry_types';
import occasionReducer from './occasion-booking';
import chatReducer from './chat';


const rootReducer = combineReducers({
    data: productReducer,
    theme: themeReducer,
    cartList: cartReducer,
    filters: filtersReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    timer: timerReducer,
    gift: giftCardReducer,
    otp: otpReducer,
    careerDepartment: CareerReducer,
    banners: BannerReducer,
    enquiries: EnquiryReducer,
    chat: chatReducer,
    occasions: occasionReducer,
    Intl
});

export default rootReducer;