import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';

const ProductSlider = (props) => {

    const [picture, setPicture] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [selectedVariant, setSelectedVariant] = useState({});
    const [isActive, setIsActive] = useState(false);

    const validateURL = (url) => {
        const parsed = new URL(global.publicUrl + url)
        return ['https:', 'http:'].includes(parsed.protocol)
    }

    const {
        item,
        onAddToCartClicked,
        onAddToWishlistClicked,
        onRemoveFromWishlistClicked,
        onAddToCompareClicked,
        wishListedItem,
        symbol
    } = props;

    useEffect(() => {
        checkItemOnWishlist(item.id)
    }, [])

    const checkItemOnWishlist = (product_id) => {
        wishListedItem.forEach(element => {
            if (element.id == product_id)
                setIsActive(true)
        });
    }

    const addToCartList = item => {
        item.selectedVariant = selectedVariant
        onAddToCartClicked(item, 1, item.selectedVariant);
        setQuantity(quantity + 1)
    };

    const handleWishlist = item => {
        item.selectedVariant = selectedVariant
        if (isActive) {
            setIsActive(false);
            onRemoveFromWishlistClicked(item, item.selectedVariant)
        }
        else {
            setIsActive(true);
            onAddToWishlistClicked(item, item.selectedVariant)
        }
    }

    const onAddToCompare = item => {
        item.selectedVariant = selectedVariant
        onAddToCompareClicked(item, item.selectedVariant)
    }

    useEffect(() => {
        setSelectedVariant(item.variants[0]);
        setPicture(item.variants[0].image.split(","));
    }, []);

    const img_fallback = (event) => {
        event.target.parentNode.childNodes.forEach(function (node) {
            if (node.nodeName == "IMG") node.src = window.no_image;
        });
    }


    const inr = new Intl.NumberFormat('en-IN', { style: 'decimal' })
    return (
        <div className="item">
            <div className="uk-card-default glitz-item uk-inline-clip uk-transition-toggle uk-light p-1 pb-3" tabIndex="0" style={{ padding: '15px' }}>
                <div className="glitz-img uk-inline-clip uk-transition-toggle" tabIndex="0">
                    <Link to={validateURL(`${process.env.PUBLIC_URL}/${item.value}`) ? `${process.env.PUBLIC_URL}/${item.value}` : ''}>
                        <img
                            src={`${picture[0]}?resize=361,361&compress=50`}
                            onError={img_fallback}
                            alt={item.name}
                        />

                        <img
                            className="uk-transition-scale-up uk-position-cover"
                            src={`${picture[1] ? picture[1] : picture[0]}?resize=361,361&compress=50`}
                            onError={img_fallback}
                            alt={item.name}
                        />
                    </Link>

                    {/* {item.variants[0].stock > 0 &&
                        <div className="uk-position-top-left wish-icon-in p-2 p-md-3">
                            <img src="/assets/images/sunny/shipping_truck.svg" width="35" data-uk-svg />
                            <div className='text-dark small'><small>Fast Delivery<br />available</small></div>
                        </div>
                    } */}


                    <div className="uk-position-top-right wish-icon-in uk-padding-small p-1 p-md-3" data-uk-tooltip={"Add to your wishlist"}>
                        <a
                            href="javascript:void(0)"
                            onClick={() => handleWishlist(item)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill={!isActive ? "none" : "#ed1313"}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke={!isActive ? "currentColor" : "#ed1313"}
                                className="w-6 h-6">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                            </svg>
                        </a>
                    </div>
                    <div className="uk-position-bottom-right wish-icon uk-transition-slide-bottom-small uk-padding-small p-1 p-md-3">
                        <ul className="uk-iconnav uk-iconnav-vertical">
                            <li className="py-1 mb-1">
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => addToCartList(item)}
                                >
                                    <img src="/assets/images/new_template/other_pages/shopping-cart-solid.svg" width="20" data-uk-svg />
                                </a>
                            </li>
                            <li className="py-1 mb-1">
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => onAddToCompare(item)}
                                >
                                    <img src="/assets/images/new_template/other_pages/arrows-rotate-solid.svg" width="20" data-uk-svg />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="uk-text-left uk-padding-small uk-padding-remove-bottom">
                    <Link to={`${process.env.PUBLIC_URL}/${item.value}`}>
                        <h5 className="uk-width-expand my-1 tw-line-clamp-2 uk-visible@l text-dark">{item.name.toUpperCase()}
                            {[...Array(50).keys()].map((key, i) => (<>&nbsp;</>))}
                        </h5>
                        <small className="uk-width-expand uk-margin-small tw-line-clamp-2 uk-hidden@l">{item.name.toUpperCase()}
                            {[...Array(50).keys()].map((key, i) => (<>&nbsp;</>))}
                        </small>
                    </Link>
                    <div className="price">
                        <span className="new-price mr-2 text-dark">{symbol} {inr.format(props.item.variants[0].offerprice)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductSlider;
