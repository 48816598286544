import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./css/mobile-sticky-navbar.css";

import algoliasearch from 'algoliasearch/lite';
import SearchItems from "./search-items";
import store from "../../store";

const StickyNavbar = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [search, setSearch] = useState(null)
    const [searchResults, setSearchResults] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [isSearching, setIsSearching] = useState(false);

    const searchClient = algoliasearch(window.rc.algoliasearch.appId, window.rc.algoliasearch.apiKey);
    const index = searchClient.initIndex(window.rc.algoliasearch.defaultIndex);

    function debounce(func) {
        var timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(undefined, args);
            }, 200);
        };
    }

    const handleSearch = useCallback(
        debounce(search => {
            index
                .search(search, {
                    hitsPerPage: 6,
                })
                .then(({ hits, nbHits }) => {
                    setSearchResults(hits);
                    setTotalProducts(nbHits);
                })
                .catch(error => console.error(error));
        }),
        []
    );

    useEffect(() => {
        if (search) {
            setIsSearching(true)
            handleSearch(search)
        }
    }, [search]);

    useEffect(() => {
        setIsSearching(false)
    }, [searchResults]);

    const displayModal = (event) => {
        setShowModal(event)
    }

    const toggleChat = () => {
        // setIsChatOpen(!isChatOpen);
        store.dispatch({ type: "WHATSAPP_CHAT"});
    };

    return (
        <div className="mobile-fix-option uk-flex uk-flex-around uk-flex-middle uk-hidden@m">
            <li className="mobile-home-page">
                <Link to={`${process.env.PUBLIC_URL}/`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="#722257"
                        className="bi bi-house-door"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"
                        >
                        </path>
                    </svg>
                </Link>
            </li>
            <li className="mobile-profile">
                <Link to={`${process.env.PUBLIC_URL}/login`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="#722257"
                        className="bi bi-person"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                        >
                        </path>
                    </svg>
                </Link>
            </li>
            <li className="mobile-chat">
                {/* <a onClick={toggleChat}> */}
                <a href="https://api.whatsapp.com/send?phone=919961870099 " target="_blank">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="#722257"
                        className="bi bi-chat"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"
                        >
                        </path>
                    </svg>
                </a>
            </li>
            <li className="mt-2 mobile-cart">
                <Link to={`${process.env.PUBLIC_URL}/cart`}>
                    <div className="d-inline-flex position-relative">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="#722257"
                            className="bi bi-cart"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                            >
                            </path>
                        </svg>
                        {props.cartList.length > 0 ? (
                            <span className="badge position-absolute">({props.cartList.length})</span>
                        ) : ("")
                        }
                    </div>
                </Link>
            </li>
            <li className="mobile-search" onClick={() => displayModal(true)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#722257"
                    className="bi bi-cart"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                    >
                    </path>
                </svg>
            </li>

            {showModal ?
                <div className="back-drop-search">
                    <div className="uk-panel uk-panel-scrollable h-100" style={{ zIndex: 9999999 }}>
                        <div className="uk-flex uk-flex-right text-light">
                            <button className="uk-button-default text-muted uk-h4 bg-light m-0" type="button" onClick={() => displayModal(false)}>x</button>
                        </div>
                        <div className="uk-width-1-1 uk-padding-small side-bar-listing uk-padding-remove-horizontal">
                            <form action="/jewellery">
                                <input
                                    autoFocus
                                    type="text"
                                    className="uk-input"
                                    placeholder="Find the best for you..."
                                    maxLength="128"
                                    value={search}
                                    name="query"
                                    onChange={(event) => setSearch(event.target.value)}
                                />

                            </form>
                            {isSearching ?
                                <div className="uk-align-center uk-background-default uk-margin-small">
                                    <div className=" uk-text-center uk-padding-small uk-flex uk-flex-middle uk-flex-center" style={{ minHeight: "280px" }}>
                                        <span className="uk-margin-small-right uk-text-lead" uk-spinner="ratio: 3"></span>
                                    </div>
                                    <Link to={`${process.env.PUBLIC_URL}/jewellery`} onClick={() => setShowModal(false)}>
                                        <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                            <h4 className="para-one uk-text-emphasis uk-margin-remove">Can't wait for search. explore the collections. <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                            </svg></h4>
                                        </div>
                                    </Link>
                                </div>
                                : searchResults.length != 0 ?
                                    <div className="uk-align-center uk-background-default uk-margin-small">
                                        <h5 className="uk-text-emphasis uk-margin-remove uk-padding-small uk-padding-remove-bottom">We are showcased <b>{totalProducts} products</b> for you </h5>
                                        <div className="uk-grid uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-3@xl uk-text-center uk-padding-small uk-padding-remove-top" data-uk-grid style={{ minHeight: "280px" }}>
                                            {searchResults.map((product, index) => (
                                                <SearchItems setShowModal={setShowModal} product={product} index={index} />
                                            ))}
                                        </div>
                                        {search ?
                                            <Link to={`${process.env.PUBLIC_URL}/jewellery?query=${search}`} onClick={() => setShowModal(false)}>
                                                <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                                    <h4 className="para-one uk-text-emphasis uk-margin-remove">Explore the products that match the key <b>"{search}"</b> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                    </svg></h4>
                                                </div>
                                            </Link> :
                                            <Link to={`${process.env.PUBLIC_URL}/jewellery`} onClick={() => setShowModal(false)}>
                                                <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                                    <h4 className="para-one uk-text-emphasis uk-margin-remove">Explore the products <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                    </svg></h4>
                                                </div>
                                            </Link>}
                                    </div> : search ?
                                        <div className="uk-align-center uk-background-default uk-margin-small">
                                            <div className="uk-text-center uk-padding-small uk-flex uk-flex-middle uk-flex-center" style={{ minHeight: "280px" }}>
                                                <div>
                                                    <img src="/assets/images/sunny/no_data.svg" style={{ height: "100px" }} />
                                                    <h4 className="para-one uk-text-emphasis uk-padding-small">No products matching</h4>
                                                </div>
                                            </div>
                                            <Link to={`${process.env.PUBLIC_URL}/jewellery`} onClick={() => setShowModal(false)}>
                                                <div className="uk-padding-small" style={{ background: "#72225721" }}>
                                                    <h4 className="para-one uk-text-emphasis uk-margin-remove">Don't get stuck with the search. explore our wide variety collection even more. <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                    </svg></h4>
                                                </div>
                                            </Link>
                                        </div>
                                        : ""}
                        </div>
                    </div>
                </div> : ""}
        </div>
    )
}

function mapStateToProps(state) {
    return { cartList: state.cartList.cart };
}
export default connect(mapStateToProps)(StickyNavbar);

