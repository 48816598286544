import { RECIEVE_OCCASION_TYPES } from '../constants/ActionTypes'

const initialState = {
    occasionTypes: [],
}

const occasionReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECIEVE_OCCASION_TYPES:
            return {
                ...state,
                occasionTypes: action.payload
            };
        default:
            return state;
    }
}

export default occasionReducer;