import React, { useEffect, useState } from "react";
import SDClient from "../../../sd_core/client";
import { setSessionData } from "../../../services";
import Loader from "../../common/spinner"
// import Loader from "../../common/spinner"

function ShippingAddressDetails(props) {

  // const [debounceLock, setDebounceLock] = useState(false);
  const [debounceLockID, setDebounceLockID] = useState(-1);
  const [pincodeStatus, setPincodeStatus] = useState({});
console.log(props.profile);

  useEffect(() => {
    if (props.profile.length > 0)
      props.setShippingDetails({
        address_country: props.profile.country,
        address_zipcode: props.profile.ship_pincode,
        address_state: props.profile.ship_state,
        address_city: props.profile.ship_city,
        address_address: props.profile.ship_address,
      })
  }, [props.profile])


  useEffect(() => {
    // console.log("Shipping Details Changed", props.shippingDetails)
    if (debounceLockID == -1) {
      setDebounceLockID(-2)
      return
    }
    debounce();
  }, [props.shippingDetails])

  useEffect(() => {
    // console.log("[SHIPPING] VOData Changed", props.VOData)
    if (props.VOData.order)
      if (props.VOData.order.shipping_address)
        props.setShippingDetails({
          address_country: props.VOData.order.shipping_address.virtual_order_address_country,
          address_zipcode: props.VOData.order.shipping_address.virtual_order_address_zipcode,
          address_state: props.VOData.order.shipping_address.virtual_order_address_state,
          address_city: props.VOData.order.shipping_address.virtual_order_address_city,
          address_address: props.VOData.order.shipping_address.virtual_order_address_address,
        })
  }, [props.VOData])

  // console.log("Render Shipping Details ", props.shippingDetails)

  const shippingDetailsync = () => {
    SDClient.endpoint("virtual_order_set_shipping_address", { ...props.shippingDetails }).then(
      (data) => {
        if (data.message.action) {
          props.setValidationTrace({ ...props.validationTrace, shipping_address: {}, });
          setPincodeStatus({
            name: data.message.pincode.Name,
            block: data.message.pincode.Block
          })
        } else {
          if (data.message.reason == "MESSAGE_VALIDATION_ERROR") {
            props.setValidationTrace({ ...props.validationTrace, shipping_address: data.message.trace, });
            setPincodeStatus({})
          }
          else if (data.message.reason == "SHIPPING_PINCODE_ERROR") {
            props.setValidationTrace({ ...props.validationTrace, shipping_address: { address_zipcode: {} } });
            setPincodeStatus({})
          }
        }
      },
      // console.log
    )
  }

  const debounce = () => {
    if (props.globalLoading.shippingDebounce) {
      clearTimeout(debounceLockID);
    }
    setDebounceLockID(setTimeout(() => {
      // console.log("DEBOUNCE CALLBACK!")
      shippingDetailsync();
      props.setGobalLoading({
        ...props.globalLoading, shippingDebounce: false
      });
    }, 1000));
    props.setGobalLoading({
      ...props.globalLoading, shippingDebounce: true
    });
  }

  const showshippingDetailsForm = () => {
    return (
      <div className="py-1">
        <div >
          <div className=" para-one">Country</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.shippingDetails.address_country}
            onChange={(e) => { props.setShippingDetails({ ...props.shippingDetails, address_country: e.target.value }) }}
          />
          {/* {props.validationTrace.shipping_address.address_country ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid country name. </h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={props.validationTrace.shipping_address.address_country ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify a valid country name. </h6>
        </div>
        <div >
          <label className=" para-one">Address</label>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.shippingDetails.address_address}
            onChange={(e) => { props.setShippingDetails({ ...props.shippingDetails, address_address: e.target.value }) }}
          />
          {/* {props.validationTrace.shipping_address.address_address ? (<h6 className="uk-text-danger uk-margin-small"> Please specify an address for shipping address.</h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={props.validationTrace.shipping_address.address_address ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify an address for shipping address.</h6>
        </div>
        <div >
          <div className=" para-one">City </div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.shippingDetails.address_city}
            onChange={(e) => { props.setShippingDetails({ ...props.shippingDetails, address_city: e.target.value }) }}
          />
          {/* {props.validationTrace.shipping_address.address_city ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid city for shipping address.</h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={props.validationTrace.shipping_address.address_city ? "failed uk-text-danger " : "passed uk-text-danger "}> Please specify a valid city for shipping address.</h6>
        </div>
        <div >
          <div className=" para-one">State</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.shippingDetails.address_state}
            onChange={(e) => { props.setShippingDetails({ ...props.shippingDetails, address_state: e.target.value }) }}
          />
          {/* {props.validationTrace.shipping_address.address_state ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid state for shipping address. </h6>) : <div className="uk-margin-small"></div>} */}
          <h6 className={props.validationTrace.shipping_address.address_state ? "failed uk-text-danger  " : "passed uk-text-danger "}> Please specify a valid state for shipping address. </h6>

        </div>
        <div >
          <div className=" para-one">Postal code</div>
          <input
            type="text"
            name="virtual_order_profile_first_name"
            className="uk-input"
            value={props.shippingDetails.address_zipcode}
            onChange={(e) => { props.setShippingDetails({ ...props.shippingDetails, address_zipcode: e.target.value }) }}
          />
          {/* {props.validationTrace.shipping_address.address_zipcode ? (<h6 className="uk-text-danger uk-margin-small"> Please specify a valid pin code for shipping address. </h6>) : <div className="uk-margin-small"></div>} */}
          {pincodeStatus.name ?
            <h6 className="uk-text-success ">{pincodeStatus.name},{pincodeStatus.block}</h6> :
            <h6 className={props.validationTrace.shipping_address.address_zipcode ? "failed uk-text-danger " : "passed uk-text-danger "}>
              Please specify a valid pin code for shipping address.
            </h6>}

        </div>
      </div>
    )
  }

  const showLoadingInputs = () => {
    return (
      <div className="py-1">
        <div className="form-group ">
          <div className=" para-one">Country</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">Address</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">City </div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">State</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
        <div className="form-group ">
          <div className=" para-one">Postal code</div>
          <input
            type="text" disabled
            name="virtual_order_profile_first_name"
            className="uk-input"
          />
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white border uk-padding-small uk-margin-bottom">
      <div className="d-flex justify-content-between">
        <p className="uk-text-lead text-uppercase para-one uk-margin-small-bottom uk-text-bold"><b>Shipping Details</b></p>
        <div className="mt-1">
          {props.globalLoading.shippingDebounce ? (<Loader />) : ""}
        </div>
      </div>
      {props.VOData.order ? showshippingDetailsForm() : showLoadingInputs()}
    </div>
  )
}

export default ShippingAddressDetails;
