import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Item.css'

const Item = (props) => {

    const [quantity, setQuantity] = useState(1)
    const [images, setImages] = useState("")
    const [selectedVariant, setSelectedVariant] = useState({})
    const [isActive, setIsActive] = useState(false);
    const [imageLoader, setImageLoader] = useState(true);
    // const [proxyFailed, setProxyFailed] = useState(false);
    const inr = new Intl.NumberFormat('en-IN', { style: 'decimal' })

    const {
        product,
        symbol,
        variantTag,
        onAddToCartClicked,
        onAddToWishlistClicked,
        onRemoveFromWishlistClicked,
        onAddToCompareClicked,
        wishListedItem
    } = props;
    const colorArray = [...new Map(product.variants.map(item => [item.color, item])).values()];
    
    useEffect(() => {
        let initialVariant = []
        initialVariant = product.variants.filter((item) => item.color == variantTag)[0]
        if (initialVariant) {
            handleVariant(initialVariant)
        } else {
            handleVariant(product.variants[0])
        }
        checkItemOnWishlist(selectedVariant.id)
    }, [product, variantTag])


    const srcset = [
        {
            "width": 300,
            "height": 300,
        },
        {
            "width": 500,
            "height": 500,
        },
        {
            "width": 800,
            "height": 800,
        }
    ];

    const checkItemOnWishlist = (product_id) => {
        wishListedItem.forEach(element => {
            if (element.variant.id == product_id) {
                setIsActive(true)
            } else {
                setIsActive(false)
            }
        });
    }

    const addToCartList = item => {
        item.selectedVariant = selectedVariant
        onAddToCartClicked(item, 1, item.selectedVariant);
        setQuantity(quantity + 1)
    };

    const handleWishlist = item => {
        if (isActive === true) {
            setIsActive(false);
            item.selectedVariant = selectedVariant
            onRemoveFromWishlistClicked(item, item.selectedVariant)
        }
        else {
            setIsActive(true);
            item.selectedVariant = selectedVariant
            onAddToWishlistClicked(item, item.selectedVariant)
        }
    }

    const onAddToCompare = item => {
        item.selectedVariant = selectedVariant
        onAddToCompareClicked(item, item.selectedVariant)
    }

    const handleMetalColor = (color) => {
        let cls = "";
        if (color == "yellow-gold")
            cls += "round-1"
        else if (color == "rose-gold")
            cls += "round-2"
        else cls += "round-3"
        if (color == selectedVariant.color) cls += " active";
        return cls;
    }

    const listHandle = (event, variant) => {

        var elems = document.querySelectorAll(".color-button.item-" + props.index + " > button");
        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });
        event.target.classList.add("active");
        handleVariant(variant)
    }


    const handleVariant = (variant) => {
        if (variant) {
            setImages(variant.image.split(","))
            setSelectedVariant(variant)
            checkItemOnWishlist(variant.id)
        }
    }

    const loadingPreview = () => {
        return (
            <div className="card-text placeholder-glow"   >
                <div className="placeholder placeholder-image w-100 " style={{ height: "302px" }}></div>
            </div>
        )
    }
    var url_1 = images[0]
    var url_2 = (images[1] ? images[1] : images[0])

    const img_url = (path) => {
        return (process.env.PUBLIC_URL == "" ? window.location.origin : process.env.PUBLIC_URL) + path;
    }

    // const proxy_resize = (height, width, url) => {
    //     if (proxyFailed)
    //         return img_url(url);
    //     else
    //         return "https://services.sprdigitallab.com/i-remake/proxy/resize/" + height + "," + width + "/" + btoa(img_url(url));
    // }

    const img_fallback = (event) => {
        // if (proxyFailed)
        event.target.parentNode.childNodes.forEach(function (node) {
            if (node.nodeName == "SOURCE") node.srcset = window.no_image;
            if (node.nodeName == "IMG") node.src = window.no_image;
        });
        // else
        //     setProxyFailed(true);
    }

    // const create_sources = (srcset, url) => {
    //     return srcset.map(res => <source media="(min-width:800px)" srcset={proxy_resize(res.width, res.height, url)} />)
    // }
    // console.log(props.index, 'props.index');
    return (
        <div key={props.index} className="px-1 mt-0">
            <div className="uk-card-default pb-2 uk-margin-small uk-margin-small-top">
                <div className="uk-text-center">
                    {imageLoader ? loadingPreview() : ""}
                    <div className="uk-inline-clip uk-transition-toggle uk-light" tabIndex="0">
                        <Link to={`${process.env.PUBLIC_URL}/${product.value}?variant_id=${selectedVariant.id}`}>
                            <picture>
                                {/* {create_sources(srcset, url_1)}
                                <img src={proxy_resize(300, 300, url_1)} */}
                                <img
                                    // src={process.env.PUBLIC_URL + images[0]}
                                    src={`${images[0]}?resize=302,302&compress=50`}
                                    alt={product.name}
                                    onError={img_fallback}
                                    loading='lazy'
                                    onLoad={() => setImageLoader(false)}
                                />
                            </picture>
                            <picture
                                className='uk-transition-scale-up uk-position-cover'>
                                {/* {create_sources(srcset, url_2)}
                                <img src={proxy_resize(300, 300, url_2)} */}
                                <img
                                    // src={process.env.PUBLIC_URL + (images[1] ? images[1] : images[0])}
                                    src={`${images[1] ? images[1] : images[0]}?resize=302,302&compress=50`}
                                    alt={product.name}
                                    onError={img_fallback}
                                    loading='lazy'
                                    onLoad={() => setImageLoader(false)}
                                />
                            </picture>
                        </Link>
                        {product.variants[0].stock > 0 &&
                            <div className="uk-position-top-left wish-icon-in p-2 p-md-3">
                                {/* <img src="/assets/images/sunny/shipping_truck.svg" width="35" data-uk-svg /> */}
                            </div>
                        }
                        <div className="uk-position-top-right wish-icon-in p-2 p-md-3">
                            <a
                                href="javascript:void(0)"
                                onClick={() => {
                                    handleWishlist(product)
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill={!isActive ? "none" : "#ed1313"}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke={!isActive ? "currentColor" : "#ed1313"}
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                                </svg>
                            </a>
                        </div>
                        <div className="uk-position-bottom-right wish-icon uk-transition-slide-bottom-small p-2 p-md-3">
                            <ul className="uk-iconnav uk-iconnav-vertical">
                                <li className='py-1 mb-1'>
                                    <a
                                        href="javascript:void(0)"
                                        item onClick={() => addToCartList(product)}>
                                        <img src="/assets/images/new_template/other_pages/shopping-cart-solid.svg" width="20" data-uk-svg />
                                    </a>
                                </li>
                                <li className='py-1 mb-1'>
                                    <a
                                        href="javascript:void(0)"
                                        onClick={() => onAddToCompare(product)}>
                                        <img src="/assets/images/new_template/other_pages/arrows-rotate-solid.svg" width="20" data-uk-svg />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {colorArray && colorArray.length > 1 && (
                            <div className={"color-button uk-position-bottom-left uk-transition-slide-bottom-small p-2 p-md-3 mb-1 item-" + props.index}>
                                {colorArray.map((vari, i) => {
                                    return (
                                        <button
                                            key={i}
                                            className={handleMetalColor(vari.color)}
                                            onClick={e => listHandle(e, vari)}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="uk-text-left px-3">
                    <Link to={`${process.env.PUBLIC_URL}/${product.value}?variant_id=${selectedVariant.id}`}>
                        <h5 className="uk-width-expand my-1 tw-line-clamp-2 uk-visible@l">{product.name.toUpperCase()}
                            {[...Array(50).keys()].map((key, i) => (<>&nbsp;</>))}
                        </h5>
                        <small className="uk-width-expand uk-margin-small tw-line-clamp-2 uk-hidden@l">{product.name.toUpperCase()}
                            {[...Array(50).keys()].map((key, i) => (<>&nbsp;</>))}
                        </small>
                    </Link>
                    <div className='d-flex justify-content-between'>
                        {selectedVariant.offerprice ?
                            <div className='uk-text-medium uk-text-bold'>{symbol} {inr.format(selectedVariant.offerprice)}
                            </div> : ""}
                        {/* {product.variants[0].stock > 0 &&
                            <div class="alert alert-success text-uppercase mb-0 p-1 small text-center" role="alert" style={{ background: "#228c22", color: "#fff", borderRadius: "20px" }}>
                                Fast Delivery Available
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item;